<template>
  <div>
    <div v-if="!addFundsFlag">
      <div class="funds-list-page">
        <PocCardTable class="irisePocCardTable filterContainer topPocCardTable">
          <template v-slot:headerTitle> 社保 / 公积金管理</template>
          <template v-slot:headerGroup>
            <el-button @click="refresh" class="resetButton">
              <i class="el-icon-refresh-left"></i>
            </el-button>
            <el-button @click="addFunds" role="add" v-permission
              >新增</el-button
            >
            <el-button
              :loading="queryLoading"
              @click="exportData"
              role="export"
              v-permission
              >导出</el-button
            >
            <el-button type="primary" @click="filterQuery">查询</el-button>
          </template>
          <template>
            <el-form :inline="true">
              <el-form-item label="名称" label-position="top">
                <el-input placeholder="请输入名称" v-model="filterConfig.name">
                </el-input>
              </el-form-item>
              <el-form-item
                label="生效日期"
                label-position="top"
                class="flex-1"
              >
                <el-date-picker
                  v-model="filterConfig.effectiveDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="状态" class="flex-1">
                <el-select
                  clearable
                  v-model="filterConfig.status"
                  filterable
                  placeholder="请选择状态"
                  class="industry-selector"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </template>
        </PocCardTable>
        <PocCardTable class="irisePocCardTable">
          <div style="margin-bottom: 12px">
            <el-button
              @click="deleteRow"
              :disabled="ifDelFlag"
              role="muldelete"
              v-permission
              >删除</el-button
            >
          </div>
          <PocTable
            v-loading="queryLoading"
            :data="tableData"
            size="large"
            stripe
            :current-page.sync="pageConfig.pageNum"
            :total="pageConfig.total"
            @size-change="pageSizeChange"
            @page-current-change="pageChange"
            :max-height="10000000"
            @selection-change="handleSelectionChange"
            @row-click="goDetail"
            ref="multipleTable"
            row-key="id"
          >
            <el-table-column
              type="selection"
              width="45"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              v-for="item in columnOptions"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="item.key === 'createTime'"
                  class="seeOrg"
                  @click.stop="distributionOrganization(scope.row)"
                  >查看</span
                >
                <span v-else class="orgName" @click="goDetail(scope.row)">{{
                  scope.row[item.key]
                }}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="150" align="center">
              <template slot-scope="scope">
                <svg-icon
                  icon="tableCopy"
                  role="copy"
                  v-permission
                  class="tablePointSvgClass"
                  title="复制"
                  @click.native="copyFunds(scope.row)"
                />
                <svg-icon
                  icon="tableEdit"
                  role="edit"
                  v-permission
                  class="tablePointSvgClass"
                  title="编辑"
                  @click.native="editFunds(scope.row)"
                />
              </template>
            </el-table-column>
          </PocTable>
        </PocCardTable>

        <el-dialog
          :visible.sync="ModalFlagSuccess"
          width="354px"
          height="200px"
          top="230px"
          title="导出提示"
        >
          <div style="text-align: center; font-size: 20px">
            {{ exportMsg }}已成功导出！
          </div>
          <div style="text-align: right; margin-top: 38px">
            <el-button type="primary" @click="close" style="margin-bottom: 20px"
              >知道了（{{ exportLeftSecond }}S）</el-button
            >
            <div class="clear"></div>
          </div>
        </el-dialog>

        <scopeTransfer
          ref="scopeTransferRef"
          @onConfirm="onConfirm"
          fromPage="fundsPage"
          dialog-title="分配组织机构"
        />
      </div>
    </div>
    <template>
      <fundsAdd
        v-if="addFundsFlag"
        ref="budgetAddForm"
        :budgetAddForm="budgetAddForm"
        :addFlag="addFlag"
        @goback="goback"
      />
    </template>
    <OrganizationsDialog ref="OrganizationsDialogRef" />
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
import OrganizationsDialog from '@/views/fundsManage/dialog/OrganizationsDialog'
import fundsAdd from './fundsAdd.vue'
const { Vuex } = $PCommon
const { mapGetters } = Vuex

const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer,
    fundsAdd,
    OrganizationsDialog
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 410 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      exportLoading: false,
      downloadLoading: false,
      deleteLoading: false,
      ModalFlagSuccess: false,
      ifDelFlag: true, //删除按钮是否可点击
      exportLeftSecond: 3,
      addFundsFlag: false, //新增组件默认不出现
      addFlag: '', //新增还是复制
      exportMsg: '',
      tableData: [],
      chooseFlag: false,
      seletAllFlag: false,
      scopeData: [],
      selectEditMsg: {
        assignedGroupList: '',
        assignedRoleList: '',
        beginTime: '',
        email: '',
        endTime: '',
        mobile: '',
        remark: '',
        status: '',
        userCode: '',
        userName: ''
      }, // 选中修改的一系列用户信息
      columnConfig: [
        {
          key: 'name',
          name: '名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'status',
          name: '状态',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'effectiveDate',
          name: '生效日期',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'invalidDate',
          name: '失效日期',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'createTime',
          name: '已分配机构',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'creatInfo',
          name: '创建信息',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        }
      ],
      filterConfig: {
        name: '',
        status: '',
        effectiveDate: ''
      },
      selectIds: [], // 选中数组id集合字符串
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      props: { multiple: true },
      manageShow: false,
      jumpParams: {},
      firstSelectItem: {},
      selectedItems: [],
      checked: false,
      budgetAddForm: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    ...mapGetters(['userInfo'])
  },

  created() {
    this.filterQuery()
  },
  methods: {
    goback() {
      this.addFundsFlag = false
      this.tableData = []
      this.filterQuery()
    },
    refresh() {
      this.filterConfig = {
        name: '',
        status: '',
        effectiveDate: ''
      }
      this.filterQuery()
    },

    // 调出穿梭框
    distributionOrganization(row) {
      this.$refs.OrganizationsDialogRef.show({
        id: row.id,
        status: 'chat'
      })
    },
    onConfirm(val) {
      this.addFundsFlag = false
      // console.log('=============',this.addFundsFlag)
    },
    addFunds() {
      this.addFundsFlag = true
      this.addFlag = { addFlag: 'add' }
    },
    copyFunds(row) {
      this.addFundsFlag = true
      this.addFlag = { addFlag: 'copy', fundsId: row.id, fundsMsg: row }
    },
    editFunds(row) {
      this.addFundsFlag = true
      this.addFlag = { addFlag: 'edit', fundsId: row.id, fundsMsg: row }
    },
    goDetail(row) {
      this.addFundsFlag = true
      this.addFlag = { addFlag: 'goDetail', fundsId: row.id, fundsMsg: row }
    },
    close() {
      this.ModalFlagSuccess = false
      this.exportLeftSecond = 3
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.queryApi()
      // this.queryLoading = true;
    },
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      const { data, code } = await this.$Api.funds.getDataList({
        ...this.filterConfig,
        ...this.pageConfig
      })
      if (code === 200) {
        data.list
          ? data.list.map((d) => {
              d['creatInfo'] = d.userName + ' ' + d.createTime
            })
          : []
        this.queryLoading = false
        this.tableData = data.list
        this.pageConfig.total = data.total
        this.$forceUpdate()
      }
    },
    handleSelectionChange(val) {
      // 选的第一个
      if (val.length) {
        this.ifDelFlag = false
        this.selectedItems = val
        let nowPageIds = this.selectedItems.map((v) => v.id)
        this.selectIds = [
          ...new Set([...this.selectIds, ...nowPageIds].filter((v) => v != ''))
        ]
      } else {
        this.ifDelFlag = true
      }
    },
    async exportData() {
      console.log('导出------')
      this.pageConfig.total = 0
      this.exportLoading = true
      await this.queryApi()
      console.log('8888888', this.pageConfig.total, this.pageConfig.total == 0)
      if (this.pageConfig.total == 0) {
        this.$message({
          type: 'error',
          message: '报表数据为空，无法导出'
        })
        this.queryLoading = false
        return
      }
      await this.$Api.funds.exportDataList2({
        ...this.filterConfig,
        ...this.pageConfig,
        ids: this.selectedItems.map((item) => item.id).toString()
      })
      this.exportLoading = false
      this.exportShowTip()
    },
    exportShowTip() {
      this.exportMsg = '报表数据'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    },
    deleteRow(index) {
      const rowId = this.selectedItems.map((item) => item.id).toString()
      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getDeleteReason(rowId)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 删除原因，子组件回调
    async getDeleteReason() {
      const { code } = await this.$Api.funds.deleteRow({
        id: this.selectIds.join()
      })
      if (code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.$refs.multipleTable.clearSelection()
        this.checked = false
        this.ifDelFlag = true
        this.selectIds = [] //清除删除数据
        this.queryApi()
      }
    },
    // 取消停用
    async lockRow(row, chooseMore, chooseMorestatus) {
      let id = ''
      let rowStatus = ''
      if (chooseMore == 1) {
        console.log('多选功能', chooseMorestatus)
        // 多选功能
        // chooseMorestatus传过来的操作动作，不是列表状态与row.status相反
        id = this.selectIds
        rowStatus = chooseMorestatus
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          status = 0
          msg = '取消停用成功!'
        } else {
          status = 1
          msg = '停用成功!'
        }
        const { code, data } = await this.$Api.dataMain.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      } else {
        id = row.id
        rowStatus = row.status
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          // 有效状态，需要停用
          status = 1
          msg = '停用成功!'
        } else {
          status = 0
          msg = '取消停用成功!'
        }
        const { code, data } = await this.$Api.dataMain.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      }
    },
    async addUser() {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'add' }
    },

    async getScopeDataList() {
      const { code, data } = await this.$Api.dataMain.getOrgUnAssignList({
        id
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        // console.log('909909', data)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.el-form {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
